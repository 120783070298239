<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          title="Generator PESEL, dowód osobisty"
          class="shrink mr-2"
          contain
          src="logo.png"
          transition="scale-transition"
          width="40"
        />
      </div>
      <v-toolbar-title class="ml-10 hidden-sm-and-down">
        <h1>Generator PESEL, nr dowodu osobistego, ...</h1>
      </v-toolbar-title>

      <v-col style="text-align: end">
        <v-btn
          large
          rounded
          outlined
          color="accent"
          title="Generuj wszystkie"
          @click="refreshAll"
        >
          <v-img
            alt="Generuj wszystkie"
            class="shrink mr-2"
            src="logo.png"
            width="30"
          />
          Generuj wszystkie
        </v-btn>
      </v-col>
      <v-icon class="mr-2">
        mdi-theme-light-dark
      </v-icon>
      <v-switch
        v-model="$vuetify.theme.dark"
        hide-details
      />
    </v-app-bar>

    <v-main>
      <Dashboard ref="dashboard" />
    </v-main>
  </v-app>
</template>

<script>
import Dashboard from './components/Dashboard'

export default {
  name: 'App',

  components: {
    Dashboard
  },
  computed: {
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  },
  methods: {
    refreshAll () {
      this.$refs.dashboard.refreshAll()
    }
  }
}
</script>
